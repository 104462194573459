@import "../../../scss/index.scss";

.register-form {
  text-align: left;

  .ant-form-item {
    &:nth-child(3),
    &:last-of-type {
      margin: 0;
    }
  }

  &__input {
    .ant-input-prefix {
      font-size: 0em + calc(22px / $defaultFontSize);
    }

    input {
      font-size: 0em + calc(20px / $defaultFontSize);
      padding: 5px 5px 5px 10px !important;

      &:focus {
        border-color: $primary-color;
        box-shadow: none;
      }
    }
  }

  &__button {
    width: 100%;
    font-size: 0em + calc(22px / $defaultFontSize);
    background-color: $primary-color;
    color: $font-light;
    height: 42px;

    &:hover {
      background-color: $primary-color-dark;
    }

    &:focus,
    &:active {
      background-color: $primary-color;
      color: $font-light;
      border: 0;
    }
  }

  &__checkbox {
    margin-top: 20px;
    font-size: 0em + calc(22px / $defaultFontSize);
  }
}
