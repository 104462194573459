@import "./scss/index.scss";

.success {
  border: 1px solid $success;
  background-color: $background-success;
}

.error {
  border: 1px solid $error;
  background-color: $background-error;
}

header {
  z-index: 99;
}

.color-1 {
  background-color: #ff0000;
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.color-2 {
  background-color: #f8bf32;
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.color-3 {
  background-color: #fdf734;
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.color-4 {
  background-color: #92d050;
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.color-5 {
  background-color: #8eaadb;
  width: 120px;
  text-align: center;
  font-weight: bold;
}

.colBorder {
  border-bottom: 1px solid gray;
}

.margin-right-minimun {
  margin-right: 2px;
}

.margin-bottom-minimun {
  margin-bottom: 5px;
}

.hide {
  display: none;
}

.text-left {
  text-align: left;
}

.text-rigth {
  text-align: right;
}

.marker-cluster-custom {
  background: #188fffc2;
  border: 5px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 60px;
  line-height: 49px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  width: 60px;
}
