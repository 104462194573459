@import "../../../scss/index.scss";

.admin-sider {
  overflow: auto;
  height: calc(100vh - 65px);
  position: fixed;
  left: 0;
  top: 65px;
  //background: #fff;
}
